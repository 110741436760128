<template>
  <sign-page
    title-text="我的请假"
    :request="request"
    :column-list="columnList"
    :title-menus="workerId ? [{key: 'add', label: '请假'}] : []"
    table-size="large"
    :data-file-parm="{pDirPath: '/ask_leave/'}"
    :form-parms-add="formParmsAdd"
    :tableActions="tableActions"
    :dealFormData="dealFormData"
    :form-parms-update="formParmsUpdate"
    :tableActionsFixed="true"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  askLeaveRequest as request
} from '@/api'

import {
  dateOperating
} from '@/fmlib'

export default {
  created () {
    this.$store.dispatch('loadWorkerList')
  },
  methods: {
    timeBj (t1, t2) {
      return dateOperating.computeDay({days: 0, date: t1}) > dateOperating.computeDay({days: 0, date: t2})
    },
    dealFormData (data) {
      if (data.startTime && data.endTime && this.timeBj(data.startTime, data.endTime)) {
        this.$notice.info({
          title: '系统提示',
          desc: '结束时间需要大于开始时间。'
        })
        throw Error()
      }
      data.workerId = this.workerId
      data.status = data.status || '待审批'
      data.pushTime = data.pushTime || dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'})
      return data
    }
  },
  computed: {
    workerId () {
      return (this.$store.getters.userPrevilege.find(v => v.objType === 'worker') || {objId: null}).objId
    },
    tableActions () {
      return [{
        key: 'data_file',
        label: '附件'
      },
      {
        key: 'edit',
        label: '修改',
        show: (data) => {
          return data.status === '待审批'
        }
      },
      {
        key: 'del',
        label: '删除',
        show: (data) => {
          return data.status === '待审批'
        }
      }]
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          label: '请假类型',
          selectDatas: ['年假', '事假', '病假', '调休假', '婚假', '产假', '陪产假', '其他'].map(v => {return {key: v, label: v}}),
          key: 'type',
          check: {
            required: true
          }
        },
        {
          type: 'datetimePicker',
          label: '开始时间',
          key: 'startTime',
          check: {
            required: true
          }
        },
        {
          type: 'datetimePicker',
          label: '结束时间',
          key: 'endTime',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '请假时长',
          key: 'timeLong',
          check: {
            required: true
          }
        },
        {
          type: 'textarea',
          label: '请假事由',
          key: 'reson',
          check: {
            required: true
          }
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList () {
      let data = [{
        title: '编号',
        sort: true,
        field: 'id'
      },
      {
        title: '请假类型',
        sort: true,
        field: 'type'
      },
      {
        title: '申请时间',
        sort: true,
        field: 'pushTime',
        render: (h, rowData) => {
          return h('div', rowData && rowData.pushTime ? rowData.pushTime.slice(0, 16) : '-')
        }
      },
      {
        title: '开始时间',
        sort: true,
        field: 'startTime',
        render: (h, rowData) => {
          return h('div', rowData && rowData.startTime ? rowData.startTime.slice(0, 16) : '-')
        }
      },
      {
        title: '结束时间',
        sort: true,
        field: 'endTime',
        render: (h, rowData) => {
          return h('div', rowData && rowData.endTime ? rowData.endTime.slice(0, 16) : '-')
        }
      },
      {
        title: '请假时长',
        sort: true,
        field: 'timeLong'
      },
      {
        title: '请假事由',
        sort: true,
        field: 'reson'
      },
      {
        title: '状态',
        sort: true,
        field: 'status'
      }]
      return data
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: request.getMy
      })
    }
  }
}
</script>
